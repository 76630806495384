<template>
  <div class="p-question-experts">
    <CommonHeader :back="beforeBack" :pinned="true">{{ $t('question.topic.expert.list') }}</CommonHeader>

    <main>
      <JoLoadMore
        ref="loadmore"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <UserItem
          v-for="user in list"
          :key="user.id"
          :user="user"
        />
      </JoLoadMore>
    </main>
  </div>
</template>

<script>
  import { limit } from '@/api'
  import * as api from '@/api/question/topics'
  import UserItem from '@/components/UserItem'

  export default {
    name: 'QuestionTopicExperts',
    components: { UserItem },
    data () {
      return {
        list: [],
      }
    },
    computed: {
      topicId () {
        return Number(this.$route.params.topicId)
      },
    },
    methods: {
      onRefresh () {
        api.getTopicExperts(this.topicId)
          .then(({ data }) => {
            this.list = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          })
      },
      onLoadMore () {
        const last = [...this.list].pop() || {}
        api.getTopicExperts(this.topicId, { after: last.id })
          .then(({ data }) => {
            this.list.push(...data)
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
      },
      beforeBack () {
        this.goBack()
        this.$destroy()
      },
    },
  }
</script>
