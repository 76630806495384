<template>
  <div class="p-question-topic-apply">
    <CommonHeader>
      {{ $t('question.topic.apply.name') }}
      <span
        slot="right"
        class="submit-btn"
        :class="{disabled}"
        @click="onSubmit"
      >
        {{ $t('submit') }}
      </span>
    </CommonHeader>

    <main>
      <div class="input-wrap">
        <TextareaInput
          v-model="title"
          :placeholder="$t('question.topic.apply.placeholder.name')"
          :maxlength="30"
          :warnlength="20"
        />
      </div>
      <div class="input-wrap">
        <TextareaInput
          v-model="description"
          :placeholder="$t('question.topic.apply.placeholder.desc')"
          :maxlength="200"
          :warnlength="150"
          :rows="10"
        />
      </div>
    </main>
  </div>
</template>

<script>
  import * as api from '@/api/question/topics'
  import TextareaInput from '@/components/common/TextareaInput.vue'

  export default {
    name: 'QuestionTopicApply',
    components: {
      TextareaInput,
    },
    data: function () {
      return {
        title: '',
        description: '',
      }
    },
    computed: {
      disabled () {
        return !this.title || !this.description
      },
    },
    methods: {
      async onSubmit () {
        const params = {
          name: this.title,
          description: this.description,
        }
        await api.postTopicApply(params)
        this.$Message.success(this.$t('question.topic.apply.success'))
        this.$router.push('/question/topics')
      },
    },
  }
</script>

<style lang="less" scoped>
.p-question-topic-apply {
  .submit-btn {
    color: @primary;

    &.disabled {
      color: #ccc;
    }
  }

  .input-wrap {
    padding: 30px 30px 0;
    overflow: hidden;
    background-color: #fff;
    border-top: 1px solid @border-color;

    &:first-child {
      border-top: none;
    }
  }
}
</style>
