<template>
  <div class="module-questions-topics">
    <!-- navs. -->
    <nav class="nav">
      <RouterLink
        to="/question/topics"
        replace
        exact
        exact-active-class="active"
      >
        {{ $t('question.topic.all') }}
      </RouterLink>
      <RouterLink
        :to="{ path: '/question/topics', query: { type: 'follow' } }"
        replace
        exact
        exact-active-class="active"
      >
        {{ $t('question.topic.follow') }}
      </RouterLink>
    </nav>

    <!-- Main -->
    <JoLoadMore
      ref="loadmore"
      @onRefresh="handleRefresh"
      @onLoadMore="handleLoadMore"
    >
      <QuestionTopicCard
        v-for="topic in topics"
        :key="topic.id"
        :topic="topic"
        :type="type"
        @follow="handleFollow"
        @unfollow="handleUnfollow"
      />
    </JoLoadMore>
  </div>
</template>

<script>
  import QuestionTopicCard from './components/QuestionTopicCard.vue'
  import * as api from '@/api/question/topics'

  export default {
    name: 'TopicList',
    components: {
      QuestionTopicCard,
    },
    data: () => ({
      topics: [],
    }),
    computed: {
      type () {
        const { type = 'hot' } = this.$route.query
        return type
      },
      loadContainer () {
        return this.$refs.loadmore
      },
      user () {
        const { CURRENTUSER: user } = this.$store.state
        return user
      },
    },
    watch: {
      type () {
        this.topics = []
        this.loadContainer.beforeRefresh()
      },
    },
    mounted () {
      this.loadContainer.beforeRefresh()
    },
    methods: {
      handleRefresh () {
        if (this.type === 'follow') {
          this.handleRefreshByFollow()

          return
        }

        this.handleRefreshByAll()
      },
      handleRefreshByAll () {
        const offset = 0
        const limit = 15
        api
          .all(offset, limit)
          .then(({ data }) => {
            this.topics = data
            this.loadContainer.afterRefresh()
            this.loadContainer.afterLoadMore(data.length < limit)
          })
          // .catch(({ response: { data } = {} }) => {
          //   this.$Message.error(data)
          //   this.loadContainer.afterRefresh()
          //   this.loadContainer.afterLoadMore(false)
          // })
      },
      handleRefreshByFollow () {
        const after = 0
        const limit = 15
        api
          .getFollowTopics(after, limit)
          .then(({ data }) => {
            this.topics = data
            this.loadContainer.afterRefresh()
            this.loadContainer.afterLoadMore(data.length < limit)
          })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
            this.loadContainer.afterRefresh()
            this.loadContainer.afterLoadMore(false)
          })
      },
      handleLoadMore () {
        if (this.type === 'follow') {
          this.handleLoadMoreByFollow()

          return
        }

        this.handleLoadMoreByAll()
      },
      handleLoadMoreByAll () {
        const offset = this.topics.length
        const limit = 15
        api
          .all(offset, limit)
          .then(({ data }) => {
            this.topics = [...this.topics, ...data]
            this.loadContainer.afterLoadMore(data.length < limit)
          })
          .catch(({ response: { data } = {} }) => {
            this.loadContainer.afterLoadMore(true)
            this.$Message.error(data)
          })
      },
      handleLoadMoreByFollow () {
        const { id: after } = this.topics[this.topics.length - 1]
        const limit = 15
        api
          .getFollowTopics(after, limit)
          .then(({ data }) => {
            this.topics = [...this.topics, ...data]
            this.loadContainer.afterLoadMore(data.length < limit)
          })
          .catch(({ response: { data } = {} }) => {
            this.loadContainer.afterLoadMore(true)
            this.$Message.error(data)
          })
      },
      handleFollow (topic) {
        api.followTopic(topic.id).then(() => {
          topic.has_follow = true
          topic.follows_count += 1
        })
      },
      handleUnfollow (topic) {
        api
          .unfollowTopic(topic.id)
          .then(() => {
            topic.has_follow = false
            topic.follows_count -= 1
            if (this.type === 'follow') {
              const newTopics = []
              this.topics.forEach(_topic => {
                if (_topic.id !== topic.id) {
                  newTopics.push(_topic)
                }
              })
              this.topics = newTopics
            }
          })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
          })
      },
    },
  }
</script>

<style lang="less" scoped>
.module-questions-topics {
  padding-top: 182px;
  padding-bottom: 1px;
  min-height: 100vh;

  .nav {
    position: fixed;
    top: 90px;
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    color: #999;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 0;
    border-bottom: solid 1px #ededed; /* no */
    z-index: 10;

    > a {
      color: #d7d8d8;
    }
    .active {
      color: #333;
    }
  }
}
</style>
