<template>
  <div>
    <SearchBar v-model="keyword" />
    <div class="p-question-search">
      <nav class="questions-nav">
        <RouterLink
          :to="{ name: 'QuestionSearch', query: {type: 'question', keyword }}"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('question.name') }}
        </RouterLink>
        <RouterLink
          :to="{ name: 'QuestionSearch', query: { type: 'topic', keyword }}"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('question.topic.name') }}
        </RouterLink>
      </nav>

      <JoLoadMore
        ref="loadmore"
        :auto-load="false"
        :show-bottom="list.length > 0"
        style="padding-top: 1.82rem"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <template v-if="type === 'question'">
          <QuestionCard
            v-for="question in list"
            :key="question.id"
            :question="question"
          />
        </template>
        <template v-else>
          <QuestionTopicCard
            v-for="topic in list"
            :key="topic.id"
            :topic="topic"
          />
        </template>
      </JoLoadMore>
      <template v-if="noData">
        <div v-if="type === 'question'" class="m-no-find" />
        <div v-else class="no-find-topics">
          <p>{{ $t('question.topic.apply.no_find') }}</p>
          <RouterLink tag="button" to="/question/topic/apply">{{ $t('question.topic.apply.suggest') }}</RouterLink>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import SearchBar from '@/components/common/SearchBar.vue'
  import QuestionTopicCard from './components/QuestionTopicCard.vue'
  import QuestionCard from './components/QuestionCard.vue'
  import { queryList as queryQuestions } from '@/api/question/questions'
  import { query as queryTopics } from '@/api/question/topics'
  import { limit } from '@/api'
  import { noop } from '@/util'

  export default {
    name: 'QuestionSearch',
    components: { QuestionTopicCard, QuestionCard, SearchBar },
    data () {
      return {
        type: 'question',
        keyword: '', // search keyword
        offset: 0, // search offset
        limit: 15,
        list: [], // search result
        noData: false,
      }
    },
    computed: {
      after () {
        const len = this.list.length
        return len > 0 ? this.list[len - 1].id : 0
      },
    },
    watch: {
      $route (to) {
        console.log(to.query)
        this.type = to.query.type || 'question'
        this.list = []
        this.offset = 0
        this.noData = false
        this.searchQuestions()
      },
      keyword (n) {
        this.$router.replace({ query: { type: this.type, keyword: n } })
      },
      type (n) {
        this.$router.push({ query: { type: n, keyword: this.keyword } })
      },
    },
    created () {
      console.log('created')
    },
    mounted () {
      const { $route: { query: { type = 'question', keyword = '' } } } = this
      this.$set(this, 'keyword', keyword)
      this.$set(this, 'type', type)
      this.searchQuestions()
    },
    methods: {
      searchQuestions (event) {
        if (!this.keyword) return
        this.offset = event === 'loadmore' ? this.list.length : 0
        this.type === 'question'
          ? this.queryQuestions()
          : this.queryTopics()
      },
      queryQuestions () {
        const params = {
          offset: this.offset,
          limit: this.limit,
          type: 'all',
          subject: this.keyword,
        }
        queryQuestions(params).then(({ data }) => {
          if (this.offset === 0) {
            this.list = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          } else {
            this.list = [...this.list, ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          }
          this.noData = !this.list.length
        })
      },
      queryTopics (callback = noop) {
        const params = {
          offset: this.offset,
          limit: this.limit,
          name: this.keyword,
        }
        queryTopics(params).then(({ data }) => {
          if (this.offset === 0) {
            this.list = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          } else {
            this.list = [...this.list, ...data]
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          }
          this.noData = !this.list.length
        })
      },
      onRefresh () {
        this.offset = 0
        this.searchQuestions('refresh')
      },
      onLoadMore () {
        this.offset = this.list.length
        this.searchQuestions('loadmore')
      },
    },
  }
</script>

<style lang="less" scoped>
.p-question-search {
  height: calc(~"100% - 180px");

  .m-head-top-title {
    padding: 0 20px 0 0;
    .m-search-box {
      width: 100%;
    }
  }

  .questions-nav {
    position: fixed;
    top: 90px;
    display: flex;
    width: 100%;
    height: 90px;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    color: #999;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 0;
    border-bottom: solid 0.01rem #d7d8d8;
    z-index: 1;

    @media screen and (min-width: 769px) {
      width: 768px;
    }
    > a {
      color: #d7d8d8;
    }
    .active {
      color: #333;
    }
  }
  .m-no-find {
    height: 100%;
  }
  .no-find-topics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #999;
    font-size: 28px;

    > button {
      margin-top: 30px;
      padding: 20px 40px;
      background-color: @primary;
      color: #fff;
      border-radius: 6px;
    }
  }
}
</style>

<style lang="less">
.p-question-search {
  .jo-loadmore-head {
    top: 182px;
  }
}
</style>
