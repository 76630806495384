<template>
  <div class="p-question-comments">
    <CommonHeader>
      {{ $t('question.comment.name') }}
      <a
        slot="right"
        class="m-btn"
        @click="onAppendComment(false)"
      >
        {{ $t('question.comment.add') }}
      </a>
    </CommonHeader>

    <JoLoadMore
      ref="loadmore"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <ul class="comment-list">
        <QuestionCommentItem
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          @reply="onAppendComment"
          @delete="onDelete"
        />
      </ul>
    </JoLoadMore>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { limit } from '@/api'
  import { getLastField } from '@/util'
  import * as api from '@/api/question/questions'
  import QuestionCommentItem from './components/QuestionCommentItem.vue'

  export default {
    name: 'QuestionComments',
    components: {
      QuestionCommentItem,
    },
    data: function () {
      return {
        comments: [],
        reply: 0,
      }
    },
    computed: {
      questionId () {
        return Number(this.$route.params.questionId)
      },
    },
    methods: {
      async onRefresh () {
        const params = { limit }
        const { data } = await api.getQuestionComments(this.questionId, params)
        this.comments = data
        this.$refs.loadmore.afterRefresh(data.length < limit)
      },
      async onLoadMore () {
        const params = { limit, after: getLastField(this.comments) }
        const { data } = await api.getQuestionComments(this.questionId, params)
        this.comments = data
        this.$refs.loadmore.afterLoadMore(data.length < limit)
      },
      onAppendComment (replyUser) {
        if (replyUser) {
          this.reply = replyUser.id
        }
        this.$bus.$emit('commentInput', {
          onOk: this.onComment,
          placeholder: replyUser ? `${this.$t('reply.name')} ${replyUser.name}:` : '',
        })
      },
      onComment (body, replyUser) {
        const params = { body, reply_user: this.reply }
        api.postQuestionComment(this.questionId, params)
          .then(() => {
            this.$Message.success(this.$t('comment.success'))
            this.$bus.$emit('commentInput:close', true)
            this.$refs.loadmore.beforeRefresh()
            this.reply = 0
          })
      },
      onDelete (commentId) {
        this.comments = _.filter(this.comments, comment => comment.id !== commentId)
      },
    },
  }
</script>
