<template>
  <ArticleCard
    ref="article"
    type="answer"
    :article="answerId"
    :liked="liked"
    :loading="loading"
    :adopted="adopted"
    class="p-answer-detail"
    @like="likeAnswer"
    @comment="$refs.comments.open()"
    @more="moreAction"
    @adopt="onAdopt"
  >
    <CommonHeader slot="head">{{ question.subject || $t('question.answer.detail') }}</CommonHeader>

    <template slot="main">
      <main class="m-flex-shrink1 m-flex-grow1 m-art m-main">
        <!-- 回答者信息 -->
        <div class="user-info-wrap" v-if="content.length>0">
          <Avatar :anonymity="answer.anonymity" :user="user" />
          <div class="user-info">
            <h2 v-if="isMine || !answer.anonymity" class="m-text-cut">{{ user.name }} <span v-if="answer.anonymity" class="gray">(匿名)</span></h2>
            <h2 v-else class="m-text-cut">{{ $t('profile.anonymity') }}</h2>
            <p v-if="isMine || !answer.anonymity" class="m-text-cut">{{ user.bio || $t('profile.default_bio') }}</p>
          </div>
          <template v-if="!isMine && !answer.anonymity" :class="{ primary: user.follower }">
            <span
              v-if="!user.follower"
              class="actived"
              @click="followUser(true)"
            >
              <svg class="m-style-svg follow-btn">
                <use xlink:href="#icon-plus" />
              </svg>
              {{ $t('follow.already') }}
            </span>
            <span v-else @click="followUser(false)">
              <svg class="m-style-svg follow-btn">
                <use xlink:href="#icon-yes" />
              </svg>
              {{ $t('follow.name') }}
            </span>
          </template>
        </div>
        <div class="m-art-body">
          <p class="m-text-box markdown-body" v-html="formatBody(content)" />
        </div>
        <div class="m-art-body"  v-if="content.length==0" style="padding-bottom: 80px;color: #b3b3b3;text-align: center">
          此回答已被删除
        </div>

        <!-- 点赞组件 -->
        <ArticleLike
          v-if="content.length>0"
          :likers="likes"
          :like-count="likeCount"
          :time="time"
          :view-count="viewsCount"
        />

        <!-- 打赏组件 -->
        <ArticleReward
          v-if="allowReward && content.length>0"
          type="answer"
          :article="answerId"
          :is-mine="isMine"
          v-bind="reward"
          @success="amount => fetchAnswerRewards(amount)"
        />
      </main>

      <!-- 评论列表 -->
      <ArticleComments
        ref="comments"
        type="answer"
        :article="answerId"
        :total.sync="commentCount"
        :fetching="fetchComing"
        @reply="replyComment"
      />
    </template>
  </ArticleCard>
</template>

<script>
import { mapState } from 'vuex'
import markdownIt from 'markdown-it'
import plusImagePlugin from 'markdown-it-plus-image'
import * as api from '@/api/question/answer'
import * as userApi from '@/api/user'
import ArticleCard from '@/page/article/ArticleCard.vue'
import ArticleLike from '@/page/article/components/ArticleLike'
import ArticleReward from '@/page/article/components/ArticleReward'
import ArticleComments from '@/page/article/components/ArticleComments'

export default {
  name: 'AnswerDetail',
  components: {
    ArticleCard,
    ArticleLike,
    ArticleReward,
    ArticleComments,
  },
  data () {
    return {
      loading: false,
      fetching: false,

      answer: {},
      rewardCount: 0,
      rewardAmount: 0,
      rewardList: [],

      fetchComing: false,
      fetchFollow: false,
    }
  },
  computed: {
    ...mapState(['CURRENTUSER']),
    allowReward () {
      return this.$store.state.CONFIG.site.reward.status
    },
    reward () {
      return {
        count: this.rewardCount,
        amount: this.rewardAmount,
        list: this.rewardList,
      }
    },
    questionId () {
      return Number(this.$route.params.questionId)
    },
    answerId () {
      return Number(this.$route.params.answerId)
    },
    question () {
      return this.answer.question || {}
    },
    user () {
      return this.answer.user || {}
    },
    time () {
      return this.answer.created_at || ''
    },
    liked: {
      get () {
        return !!this.answer.liked
      },
      set (val) {
        this.answer.liked = val
      },
    },
    likes () {
      return this.answer.likes || []
    },
    collected: {
      get () {
        return this.answer.collected
      },
      set (val) {
        this.answer.collected = val
      },
    },
    likeCount: {
      get () {
        return this.answer.likes_count || 0
      },
      set (val) {
        this.answer.likes_count = ~~val
      },
    },
    viewsCount () {
      return this.answer.views_count || 0
    },
    commentCount: {
      get () {
        return this.answer.comments_count || 0
      },
      set (val) {
        this.answer.comments_count += val
      },
    },
    content () {
      const { body = '' } = this.answer
      return body
    },
    isMine () {
      return this.user.id === this.CURRENTUSER.id
    },
    isMyQuestion () {
      return this.question.user_id === this.CURRENTUSER.id
    },
    isWechat () {
      return this.$store.state.BROWSER.isWechat
    },
    adopted () {
      return !!this.answer.adoption
    },
  },
  mounted () {
    this.fetchAnswer()
  },
  methods: {
    getAvatar (avatar) {
      avatar = avatar || {}
      return avatar.url || null
    },
    formatBody (body) {
      return markdownIt({
        html: true,
      })
        .use(plusImagePlugin, `${this.$http.defaults.baseURL}/files/`)
        .render(body)
    },
    likeAnswer () {
      if (this.fetching) return
      this.fetching = true
      if (this.liked) {
        api
          .unlike(this.answerId)
          .then(() => {
            this.liked = false
            this.likeCount -= 1
            this.answer.likes = this.answer.likes.filter(like => {
              return like.user_id !== this.CURRENTUSER.id
            })
          })
          .finally(() => {
            this.fetching = false
          })
      } else {
        api
          .like(this.answerId)
          .then(() => {
            this.liked = true
            this.likeCount += 1
            if (this.answer.likes.length < 5) {
              this.answer.likes.push({
                user: this.CURRENTUSER,
                id: +new Date(),
                user_id: this.CURRENTUSER.id,
              })
            }
          })
          .finally(() => {
            this.fetching = false
          })
      }
    },
    moreAction () {
      const actions = []
      if (!this.collected) {
        actions.push({
          text: this.$t('collect.name'),
          method: () => {
            api.collect(this.answerId).then(() => {
              this.collected = true
              this.$Message.success(this.$t('collect.success'))
            })
          },
        })
      } else {
        actions.push({
          text: this.$t('collect.cancel'),
          method: () => {
            api.unCollect(this.answerId).then(() => {
              this.collected = false
              this.$Message.success(this.$t('collect.cancel'))
            })
          },
        })
      }
      if (!this.isMine) {
        actions.push({
          text: this.$t('report.name'),
          method: () => {
            this.$bus.$emit('report', {
              type: 'answer',
              payload: this.answerId,
              username: this.answer.anonymity ? this.$t('profile.anonymity') : this.answer.user.name,
              reference: this.answer.body,
            })
          },
        })
      }
      this.$bus.$emit('actionSheet', actions)
    },
    fetchAnswerRewards (inc = 0) {
      if (inc) {
        this.rewardCount += 1
        this.rewardAmount += inc
      } else {
        const { rewarder_count: count = 0, rewarder_amount: amount = 0 } = this.answer
        this.rewardAmount = Number(amount)
        this.rewardCount = Number(count)
      }
      api.getAnswerRewards(this.answerId, { limit: 10 })
        .then(({ data }) => {
          this.rewardList = data
        })
    },
    fetchAnswer () {
      if (this.loading) return
      this.loading = true

      api.getAnswer(this.answerId).then(({ data }) => {
        this.answer = data
        this.loading = false
        document.title = this.question.subject
        this.reward.count = data.rewarder_count
        this.reward.amount = data.rewards_amount
        this.rewardList = data.rewarders
        this.fetchAnswerComments()
      }) .catch(err => {
        this.loading = false
      });
    },
    fetchAnswerComments () {
      this.$refs.comments.fetch()
    },
    onAdopt () {
      api.adoptionAnswer(this.questionId, this.answerId)
        .then(() => {
          this.answer.adoption = 1
          this.$Message.success(this.$t('question.answer.adoption_success'))
        })
    },
    replyComment (comment) {
      const actions = []
      // 是否是自己的评论
      if (comment.user_id === this.CURRENTUSER.id) {
        actions.push({
          text: this.$t('comment.delete.name'),
          method: () => this.$refs.comments.delete(comment.id),
        })
      } else {
        actions.push({
          text: this.$t('reply.name'),
          method: () => this.$refs.comments.open(comment.user),
        })
        actions.push({
          text: this.$t('report.name'),
          method: () => {
            this.$bus.$emit('report', {
              type: 'postComment',
              payload: comment.id,
              username: comment.user.name,
              reference: comment.body,
            })
          },
        })
      }
      this.$bus.$emit('actionSheet', actions)
    },
    followUser (status) {
      if (this.fetchFollow) return
      this.fetchFollow = true
      status = status ? 'unFollow' : 'follow'

      userApi.followUserByStatus({ id: this.user.id, status }).then(() => {
        this.fetchFollow = false
        this.user.follower = !this.user.follower
      })
    },
  },
}
</script>

<style lang="less" scoped>
.p-answer-detail {
  .user-info-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    height: 120px;
    border-bottom: 1px solid #ededed; /* no */

    .user-info {
      flex: auto;
      margin-left: 20px;
      font-size: 26px;
      overflow: hidden;

      .gray,
      > p {
        color: #999;
      }

      + span {
        flex: none;
        border: 1px solid #ccc; /* no */
        color: #ccc;
        border-radius: 10px;
        font-size: 28px;
        padding: 4px 10px;
        display: inline-block;
        width: 5em;
        text-align: center;

        .follow-btn {
          color: #ccc;
          width: 20px;
          height: 20px;
          vertical-align: baseline;
        }

        &.actived {
          color: @primary;
          border-color: @primary;

          .follow-btn {
            color: @primary;
          }
        }
      }
    }
  }
}
</style>
