<template>
  <div class="p-topic-detail">
    <CommonHeader :pinned="true">{{ topic.name || $t('question.topic.detail') }}</CommonHeader>

    <!-- Topic base -->
    <main>
      <div v-if="loading" class="m-pos-f m-spinner" />

      <div class="topic">
        <img :src="avatar" class="avatar">
        <div class="title">
          <h3 class="topic-name m-text-cut">{{ topic.name }}</h3>
          <div class="label">
            <span>{{ topic.follows_count }}</span> {{ $t('follow.name') }} ·
            <span>{{ topic.questions_count }}</span> {{ $t('question.name') }}
          </div>
        </div>

        <button
          v-if="topic.has_follow"
          class="follow active"
          @click="handleUnfollow(topic)"
        >
          <svg class="m-style-svg m-svg-mini">
            <use xlink:href="#icon-yes" />
          </svg>{{ $t('follow.already') }}
        </button>
        <button
          v-else
          class="follow"
          @click="handleFollow(topic)"
        >
          <svg class="m-style-svg m-svg-mini">
            <use xlink:href="#icon-plus" />
          </svg>{{ $t('follow.name') }}
        </button>
      </div>

      <!-- Topic desc -->
      <div class="topic-desc">{{ $t('question.topic.desc') }}：{{ topic.description }}</div>

      <!-- Experts -->
      <RouterLink
        to="experts"
        append
        tag="div"
        class="experts"
      >
        <span>{{ topic.experts_count | t('question.topic.expert.count') }}</span>
        <div class="users">
          <Avatar
            v-for="(user, index) in topic.experts"
            :key="user.id"
            :user="user"
            :style="[{ zIndex: topic.experts.length - index }]"
            :readonly="true"
            size="tiny"
            class="user"
          />
        </div>
      </RouterLink>

      <!-- Types nav -->
      <nav ref="types" class="types">
        <RouterLink
          :to="{ path: `/question-topics/${topicId}` }"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('hot') }}
        </RouterLink>
        <RouterLink
          :to="{ path: `/question-topics/${topicId}`, query: { type: 'excellent' } }"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('excellent') }}
        </RouterLink>
        <RouterLink
          :to="{ path: `/question-topics/${topicId}`, query: { type: 'reward' } }"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('group.reward') }}
        </RouterLink>
        <RouterLink
          :to="{ path: `/question-topics/${topicId}`, query: { type: 'new' } }"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('newest') }}
        </RouterLink>
        <RouterLink
          :to="{ path: `/question-topics/${topicId}`, query: { type: 'all' } }"
          replace
          exact
          exact-active-class="active"
        >
          {{ $t('all') }}
        </RouterLink>
      </nav>

      <!-- Questions -->
      <JoLoadMore
        ref="loadmore"
        class="questions"
        @onRefresh="onRefresh"
        @onLoadMore="onLoadMore"
      >
        <QuestionCard
          v-for="question in questions"
          :key="question.id"
          :question="question"
        />
      </JoLoadMore>
    </main>
  </div>
</template>

<script>
  import QuestionCard from './components/QuestionCard.vue'
  import * as api from '@/api/question/topics'

  export default {
    name: 'QuestionTopicDetail',
    components: {
      QuestionCard,
    },
    data () {
      return {
        topic: {},
        oldId: undefined,
        loading: true,
        questions: [],
        typeNavOffsetTop: 0,
        followLock: false,
      }
    },
    computed: {
      topicId () {
        return Number(this.$route.params.topicId)
      },
      type () {
        const { type = 'hot' } = this.$route.query
        return type
      },
      avatar () {
        const avatar = this.topic.avatar || {}
        return avatar.url || null
      },
    },
    watch: {
      type () {
        this.$refs.loadmore.beforeRefresh()
      },
    },
    created () {
      this.fetchTopic()
    },
    activated () {
      if (this.topicId !== this.oldId) {
        this.loading = true
        this.fetchTopic()
      }
    },
    mounted () {
      this.typeNavOffsetTop = this.$refs.types.offsetTop
      document.addEventListener('scroll', this.handleScrolling)
    },
    methods: {
      fetchTopic () {
        api.show(this.topicId)
          .then(({ data }) => {
            this.loading = false
            this.topic = data
            document.title = this.topic.name
          })
      },
      onRefresh () {
        const offset = 0
        const limit = 15
        api
          .questions(this.topicId, this.type, offset, limit)
          .then(({ data }) => {
            this.questions = data
            this.$refs.loadmore.afterRefresh(data.length < limit)
          })
          .catch(({ response: { data } = {} }) => {
            this.$refs.loadmore.afterRefresh(true)
            this.$Message.error(data)
          })
      },
      onLoadMore () {
        const offset = this.questions.length
        const limit = 15
        api
          .questions(this.topicId, this.type, offset, limit)
          .then(({ data }) => {
            this.questions.push(...data)
            this.$refs.loadmore.afterLoadMore(data.length < limit)
          })
      },
      handleFollow (topic) {
        if (this.followLock) return
        this.followLock = true
        api.followTopic(topic.id)
          .then(() => {
            this.followLock = false
            topic.has_follow = true
            topic.follows_count += 1
          })
      },
      handleUnfollow (topic) {
        if (this.followLock) return
        this.followLock = true
        api.unfollowTopic(topic.id)
          .then(() => {
            this.followLock = false
            topic.has_follow = false
            topic.follows_count -= 1
            if (this.type === 'follow') {
              const newTopics = []
              this.topics.forEach(_topic => {
                if (_topic.id !== topic.id) {
                  newTopics.push(_topic)
                }
              })
              this.topics = newTopics
            }
          })
      },
    },
  }
</script>

<style lang="less" scoped>
.p-topic-detail {
  main {
    width: 100%;
    height: auto;
  }

  .share {
    width: 40px;
    height: 90px;
    background-color: transparent;
    margin-top: 20px;

    > .icon {
      width: 40px;
      height: 40px;
    }
  }

  .topic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 175px;
    padding: 28px 43px;
    background-color: #fff;
    border-bottom: solid 1px #ededed; /* no */

    > .avatar {
      flex: none;
      width: 120px;
      height: 120px;
      margin-right: 18px;
      background-color: #ededed;
    }

    .title {
      flex: auto;
      min-width: 0;

      .topic-name {
        display: block;
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        color: #333;
      }

      .label {
        margin-top: 19px;
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        color: #666;

        > span {
          color: @primary;
        }
      }
    }

    .follow {
      flex: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      min-width: 6em;
      border: solid 1px @primary;/*no*/
      border-radius: 8px;
      background-color: #fff;
      color: @primary;
      font-size: 24px;
      outline: none;

      .m-svg-mini {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      &.active {
        color: #ccc;
        border-color: #ccc;
      }
    }
  }

  .topic-desc {
    width: 100%;
    padding: 29px 30px;
    background-color: #fff;
    color: #666;
    font-size: 28px;
  }

  .experts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    border-top: solid 1px #ededed; /* no */
    background-color: #fff;

    > span {
      font-size: 28px;
      color: #999;
    }

    .users {
      display: flex;
      flex-direction: row;

      .user {
        position: relative;
        border: solid 1px #fff; /* no */
        margin-left: -20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .types {
    position: relative;
    top: 110px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -100px;
    border-bottom: solid 0.01rem #ededed;
    background: #fff;
    color: #999;
    z-index: 1;
    font-size: 30px;

    > a {
      color: #999;
    }

    .active {
      color: #333;
    }
  }

  .questions {
    padding-top: 110px;
  }
}
</style>
