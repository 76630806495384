<template>
  <div class="p-question-reward">
    <CommonHeader>
      {{ $t('question.reward.public') }}
    </CommonHeader>

    <main>
      <div v-if="rewardItems.length" class="set-reward">
        <p>{{ $t('question.reward.set_amount') }}</p>
        <ul class="reward-items">
          <li
            v-for="item in rewardItems"
            :key="item"
            :class="{active: item === selected}"
            @click="selected = item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <FormInputItem
        v-model="custom"
        :label="$t('question.reward.custom_amount')"
        :placeholder="$t('question.reward.input_amount')"
      >
        <span slot="right">{{ $t('currency.unit') }}</span>
      </FormInputItem>
    </main>

    <footer>
      <p class="rule" @click="showRule">
        <svg class="m-icon-svg m-svg-small"><use xlink:href="#icon-wallet-warning" /></svg>
        {{ $t('question.reward.rule') }}
      </p>
      <button
        class="submit-button"
        :disabled="disabled"
        @click="onSubmit"
      >
        {{ $t('confirm') }}
      </button>
    </footer>
  </div>
</template>

<script>
  import * as api from '@/api/question/questions'

  export default {
    name: 'QuestionReward',
    data () {
      return {
        custom: '',
        selected: '',
      }
    },
    computed: {
      questionId () {
        return this.$route.params.questionId
      },
      rewardItems () {
        const { reward = {} } = this.$store.state.CONFIG.site
        return (reward.amounts || '').split(',').map(item => Number(item))
      },
      amount () {
        return this.custom || this.selected
      },
      disabled () {
        if (!this.amount) return true
        return false
      },
      rule () {
        return this.$store.state.CONFIG['Q&A'].reward_rule || ''
      },
    },
    watch: {
      selected (newVal) {
        if (newVal) this.custom = ''
      },
      custom (newVal) {
        if (newVal) this.selected = ''
      },
    },
    mounted () {
      if (this.rewardItems.length) this.selected = this.rewardItems[0]
    },
    methods: {
      onSubmit () {
        api.setQuestionReward(this.questionId, { amount: this.amount })
          .then(() => {
            this.$Message.success(this.$t('question.reward.success'))
            this.goBack()
          })
      },
      showRule () {
        this.$bus.$emit('popupDialog', {
          title: `${this.$t('question.reward.rule')}`,
          content: this.rule,
        })
      },
    },
  }
</script>

<style lang="less" scoped>
.p-question-reward {
  > main {
    background-color: #fff;
  }

  .set-reward {
    padding: 20px;
    font-size: 28px;
    color: #999;
    margin-bottom: 20px;

    .reward-items {
      display: flex;
      justify-content: space-between;

      > li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-top: 20px;
        width: 30%;
        height: 70px;
        border: 1px solid @border-color;
        border-radius: 8px;

        &.active {
          color: @primary;
          border-color: @primary;
        }
      }
    }
  }

  .c-form-input-item {
    border-top: 1px solid @border-color; /* no */

    /deep/ label {
      width: 6em;
      color: #333;
    }
    /deep/ .input-wrap input {
      text-align: right;
    }
  }

  footer {
    padding: 20px;

    .rule {
      display: flex;
      font-size: 28px;
      margin: 8px 0 16px;
      color: #999;

      .m-icon-svg {
        margin-right: 0.5em;
        color: #999;
      }
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
      margin-top: 40px;
      border-radius: 8px;
      background-color: @primary;
      color: #fff;
    }
  }
}
</style>
