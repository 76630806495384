<template>
  <div class="p-question-detail" @click="showInvitation=false">
    <CommonHeader :pinned="true">
      {{ question.subject || $t('question.detail') }}
      <span slot="right">
        <svg class="m-style-svg m-svg-def" @click="onMoreClick">
          <use xlink:href="#icon-more" />
        </svg>
      </span>
    </CommonHeader>

    <div class="container">
      <div v-if="loading && !question.id" class="m-pos-f m-spinner" />
      <JoLoadMore
        ref="topicQuestionsLoadMore"
        :auto-load="false"
        @onRefresh="fetchQuestion"
        @onLoadMore="onLoadMore"
      >
        <!-- Main -->
        <div class="main">
          <!-- Topics -->
          <div class="main-topics">
            <RouterLink
              v-for="topic in topics"
              :key="topic.id"
              :to="`/question-topics/${topic.id}`"
              class="label"
            >
              {{ topic.name }}
            </RouterLink>
          </div>

          <!-- Title -->
          <h3 class="main-title">{{ question.subject }}</h3>

          <!-- author -->
          <p class="main-author">
            <Avatar
              :user="user"
              size="nano"
              :anonymity="!user.name"
            />
            &nbsp;
            <span> {{ username }} </span>
          </p>

          <!-- Body -->
          <div class="markdown-body" v-html="htmlBody" />

          <!-- watch -->
          <div class="main-watch">
            <div class="main-watch-count">
              <span class="follow-count">{{ question.watchers_count }} {{ $t('follow.name') }}</span>
              <span v-if="question.amount" class="shang split"><span>{{ $t('article.badge.reward') }}</span> {{ question.amount }} </span>
              <span v-if="onlookersTotal" class="shang split"><span>{{ $t('article.badge.onlook') }}</span> {{ onlookersTotal }} </span>
            </div>

            <!-- Watch question button -->
            <button
              v-if="question.watched"
              class="main-watch-follow active"
              @click="handleUnwatch"
            >
              <svg class="m-style-svg follow-btn">
                <use xlink:href="#icon-yes" />
              </svg>
              {{ $t('follow.already') }}
            </button>
            <button
              v-else
              class="main-watch-follow"
              @click="handleWatch"
            >
              <svg class="m-style-svg follow-btn">
                <use xlink:href="#icon-plus" />
              </svg>
              {{ $t('follow.name') }}
            </button>
          </div>
        </div>

        <!-- Button -->
        <div class="main-button">
          <div class="button" @click.prevent.stop="onRewardClick">
            <svg class="main-button-icon" fill="#666">
              <use :xlink:href="`#icon-question-${question.amount ? 'reward' : 'unreward'}`" />
            </svg>
            <template v-if="invitations.length">{{ $t('question.answer.invite[0]') }}</template>
            <template v-else>{{ question.amount ? 'question.answer.invite[1]' : 'question.answer.invite[2]' | t }}</template>

            <div v-if="showInvitation && invitations.length > 0" class="show-invitations">
              <div v-for="user in invitations" :key="user.id">
                <Avatar :user="user" size="small"/><a class="txt" :href="`/users/${user.id}`">{{ user.name }}</a>
              </div>
            </div>
          </div>

          <div
            v-if="question.my_answer"
            class="button"
            @click="gotoMyAnswer"
          >
            {{ $t('question.answer.view') }}
          </div>
          <div
            v-else
            class="button"
            @click="addAnswer"
          >
            <svg class="main-button-icon" fill="#666">
              <use xlink:href="#icon-question-add" />
            </svg>
            {{ $t('question.answer.add') }}
          </div>
        </div>

        <!-- Amswers -->
        <div class="answers-tool">
          <div>{{ question.answers_count | t('question.answer.count') }}</div>
          <button @click="showOrderPopup">
            {{ answersTimeOrder ? 'question.answer.sort[0]' : 'question.answer.sort[1]' | t }}
            <svg fill="#999" class="icon">
              <use xlink:href="#icon-list" />
            </svg>
          </button>
        </div>

        <!-- Answer list -->
        <QuestionAnswerCard
          v-for="answer in answers"
          :key="answer.id"
          :answer="answer"
          :allow-adopt="allowAdopt(answer)"
          @update:answer="val => updateAnswer(val)"
        />
      </JoLoadMore>
    </div>
    <PasswordConfirm ref="password" @submit="applyAmount" />
  </div>
</template>

<script>
  import _ from 'lodash'
  import { render } from '@/util/markdown'
  import * as api from '@/api/question/questions'
  import { listByDefault, listByTime } from '@/api/question/answer'
  import QuestionAnswerCard from './components/QuestionAnswerCard'
  import PasswordConfirm from '@/components/common/PasswordConfirm.vue'

  export default {
    name: 'QuestionDetail',
    components: {
      QuestionAnswerCard,
      PasswordConfirm
    },
    data () {
      return {
        question: {},
        answersTimeOrder: false,
        answers: [],
        loading: true,
        showInvitation: false,
      }
    },
    computed: {
      currentCurrency () {
        const user = this.$store.state.CURRENTUSER
        return user.currency.sum || 0
      },
      questionId () {
        return Number(this.$route.params.questionId)
      },
      /**
       * The loged user.
       *
       * @return {Object}
       * @author Seven Du <shiweidu@outlook.com>
       */
      logedUser () {
        const { CURRENTUSER: user } = this.$store.state
        return user
      },

      /**
       * The loged user is deiter?
       *
       * @return {boolean}
       * @author Seven Du <shiweidu@outlook.com>
       */
      editer () {
        // 请不要删除，目前暂时不需要管理员可修改问题。
        // 后续需要增加的功能。
        // const { roles = [] } = this.logedUser;
        // for (let index in roles) {
        //   let role = roles[index];
        //   if (role.name === 'founder') {
        //     return true;
        //   }
        // }

        return false
      },
      user () {
        return this.question.user || {}
      },
      topics () {
        return this.question.topics || []
      },
      isMine () {
        return this.logedUser.id === this.question.user_id
      },
      htmlBody () {
        const { body = '' } = this.question
        return render(body)
      },
      answerRequestMethod () {
        return this.answersTimeOrder ? listByTime : listByDefault
      },
      invitations () {
        return this.question.invitations || []
      },
      adoptionAnswers () {
        return this.question.adoption_answers || []
      },
      invitationAnswers () {
        return this.question.invitation_answers || []
      },
      onlookersTotal () {
        return this.invitationAnswers
          .map(item => Number(item.onlookers_total))
          .reduce((a, b) => a + b, 0)
      },
      username () {
        if (!this.user.name) return '匿名用户'
        let name = this.user.name
        if (this.question.anonymity) name += '(匿名)'
        return name
      },
      app_amount () {
        let qa = this.$lstore.getData('BOOTSTRAPPERS')['Q&A']

        return qa.apply_amount || 0
      }
    },
    watch: {
      answersTimeOrder (newRoute, oldRoute) {
        if (newRoute.path === oldRoute.path) {
          this.answers = []
          this.$refs.topicQuestionsLoadMore.beforeRefresh()
        }
      },
    },
    created () {
      this.fetchQuestion()
    },
    methods: {
      fetchQuestion () {
        this.loading = true
        api.show(this.questionId)
          .then(({ data }) => {
            this.loading = false
            this.question = data
            document.title = this.question.subject
            this.onRefresh()
          })
      },
      onRefresh () {
        if (!this.questionId) return this.$refs.topicQuestionsLoadMore.afterRefresh()
        this.answerRequestMethod(this.questionId)
          .then(({ data }) => {
            this.answers = data
            const iAnswers = this.invitationAnswers.map(ia => ia.id)
            // mixin adoption answers
            this.answers.unshift(...this.adoptionAnswers.filter(aa => {
              return !iAnswers.includes(aa.id)
            }))
            // mixin invitation answers
            this.answers.unshift(...this.invitationAnswers)
            this.$refs.topicQuestionsLoadMore.afterRefresh(data.length < 15)
          })
      },
      onLoadMore () {
        if (!this.answers.length) return
        this.answerRequestMethod(this.questionId, this.answers.length)
          .then(({ data }) => {
            this.$refs.topicQuestionsLoadMore.afterLoadMore(data.length < 15)
            this.answers.push(...data)
          })
          .catch(({ response: { data } = {} }) => {
            this.$refs.topicQuestionsLoadMore.afterLoadMore(true)
            this.$Message.error(data)
          })
      },
      handleWatch () {
        api.watch(this.questionId)
          .then(() => {
            this.question.watched = true
            this.question.watchers_count += 1
          })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
          })
      },
      handleUnwatch () {
        api.unwatch(this.questionId)
          .then(() => {
            this.question.watched = false
            this.question.watchers_count -= 1
          })
          .catch(({ response: { data } = {} }) => {
            this.$Message.error(data)
          })
      },
      allowAdopt (answer) {
        return !this.question.adoption_answers.length &&
          answer.adoption === 0 &&
          this.isMine
      },
      addAnswer () {
        this.$router.push({ path: `/question/${this.questionId}/answers/add` })
      },
      gotoMyAnswer () {
        const { my_answer: { id = '' } } = this.question
        this.$router.push({
          path: `/questions/${this.question.id}/answers/${id}`,
        })
      },
      showOrderPopup () {
        const actions = [
          {
            text: this.$t('question.answer.sort[0]'),
            method: () => {
              this.answersTimeOrder = true
            },
          },
          {
            text: this.$t('question.answer.sort[1]'),
            method: () => {
              this.answersTimeOrder = false
            },
          },
        ]
        this.$bus.$emit('actionSheet', actions)
      },
      onMoreClick () {
        const actions = []
        if (this.isMine) {
          actions.push({
            text: this.$t('question.apply_choice.text'),
            method: () => {
              setTimeout(() => {
                this.$bus.$emit('payfor', {
                  title: this.$t('question.apply_choice.title'),
                  amount: this.app_amount,
                  content: this.$t('question.apply_choice.content', { amount: this.app_amount }),
                  confirmText: this.$t('question.apply_choice.confirm'),
                  cancelText: this.$t('question.apply_choice.cancel'),
                  onOk: () => {
                    this.showPasswordConfirm()
                  },
                })
              }, 200)
            },
          })
          actions.push({
            text: '编辑',
            method: () => {
              setTimeout(() => {
                this.$router.push({ path: `/questions/${this.questionId}/edit` })
              }, 200)
            },
          })
          actions.push({
            text: '删除',
            method: () => {
              setTimeout(() => {
                const actions = [
                  {
                    text: '删除',
                    style: { color: '#f4504d' },
                    method: () => {
                      api.deleteQuestion(this.questionId)
                        .then(() => {
                          this.$Message.success('删除成功')
                          this.goBack()
                        })
                    },
                  },
                ]
                this.$bus.$emit('actionSheet', actions, '取消', '确认删除?')
              }, 200)
            },
          })
          actions.push({
            text: this.$t('comment.name'),
            method: () => {
              this.$router.push({ name: 'QuestionComments', params: { questionId: this.question.id } })
            },
          })
        } else {
          actions.push({
            text: this.$t('comment.name'),
            method: () => {
              this.$router.push({ name: 'QuestionComments', params: { questionId: this.question.id } })
            },
          })
          actions.push({
            text: this.$t('report.name'),
            method: () => {
              this.$bus.$emit('report', {
                type: 'question',
                payload: this.question.id,
                username: this.question.anonymity ? this.$t('profile.anonymity') : this.question.user.name,
                reference: this.question.subject,
              })
            },
          })
        }
        
        this.$bus.$emit('actionSheet', actions)
      },
      updateAnswer (answer) {
        this.answers = _.unionBy([answer], this.answers, 'id')
      },
      onRewardClick () {
        if (this.invitations.length) {
          this.showInvitation = !this.showInvitation
        // TODO 邀请悬赏页面
        } else if (this.isMine) {
          if (!this.question.amount) {
            this.$router.push({ name: 'QuestionReward', params: { questionId: this.question.id } })
          }
        }
      },
      showPasswordConfirm () {
        if (this.currentCurrency < this.app_amount) {
          this.$Message.error(this.$t('currency.insufficient'))
          this.cancel()
          return this.$router.push({ name: 'currencyRecharge' })
        }
        this.$refs.password.show()
      },
      cancel () {
        const actions = [
          { text: this.$t('confirm'), method: this.goBack },
        ]
        this.$bus.$emit(
          'actionSheet',
          actions,
          this.$t('cancel'),
          this.$t('news.post.draft_confirm'),
        )
      },
      applyAmount (password) {
        api.applyQuestion(this.questionId, {password})
          .then(() => {
            this.$Message.success('申请成功')
          })
      }
    },
  }
</script>

<style lang="less" scoped>
.p-question-detail {
  .tabbar {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 94px;
    width: 100%;
    background-color: #fff;
    bottom: 0;
    border-top: solid 1px #d7d8d8; /* no */

    @media screen and (min-width: 769px) {
      width: 768px;
    }

    .tabbar-item {
      flex-grow: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #999;
      font-size: 12px;
    }

    .tabbar-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 6px;
    }
  }

  .main {
    background-color: #fff;
    padding: 30px;
    width: 100%;
    max-width: 100%;
    height: auto;

    .main-topics {
      width: 100%;
      height: auto;

      > .label {
        display: inline-block;
        background-color: #efefef;
        border-radius: 20px;
        font-size: 24px;
        color: #666;
        padding: 6px 30px;
        margin-right: 14px;
        margin-bottom: 14px;
      }
    }

    .main-title {
      font-size: 36px;
      color: #333;
      font-weight: normal;
      line-height: 1.4;
      margin: 12px 0;
    }

    .main-author {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      font-size: 80%;
      color: #ccc;

      > span {
        margin-left: 16px;
        color: #666666;
      }
    }

    .main-watch {
      width: 100%;
      font-size: 26px;
      color: #999;
      margin-top: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .main-watch-follow {
        height: 50px;
        width: 5.5em;
        padding: 0;
        border: solid 2px @primary;
        border-radius: 8px;
        background-color: #fff;
        color: @primary;
        font-size: 26px;
        line-height: 5px;
        outline: none;

        > span {
          width: 20px;
          height: 20px;
          font-size: 36px;
          margin-right: 2px;
          vertical-align: middle;
        }

        .follow-btn {
          color: @primary;
          width: 20px;
          height: 20px;
          vertical-align: baseline;
        }

        &.active {
          color: #ccc;
          border: solid 2px #ccc;

          .follow-btn {
            color: #ccc;
          }
        }
      }

      .main-watch-count {

        .split::before{
          content: '·';
          margin: 0 12px;
          color: @text-color3;
        }

        .shang {
          color: @warning;

          > span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: 34px;
            border: solid 1px currentColor; /* no */
            border-radius: 4px;
            font-size: 22px;
          }
        }
      }
    }
  }

  .main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-top: solid 1px #ededed; /* no */
    font-size: 28px;
    background-color: #fff;
    padding: 30px 0;
    position: relative;

    > .button {
      flex: auto;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #666;
      border-right: 1px solid #ededed; /* no */

      &:last-child {
        border-right: none;
      }
    }

    .main-button-icon {
      width: 30px;
      height: 30px;
      margin-top: -2px;
      margin-right: 20px;
    }

    .show-invitations {
      position: absolute;
      top: 0.8rem;
      background: #fff;
      border-radius: 0.06rem;
      padding: 0.2rem 0.3rem;
      box-shadow: 0 -1px 3px rgba(26, 26, 26, 0.1);
      font-size: 18px;
      
      a.txt {
        margin-left: 10px;
        color: #666;
      }
    }
  }

  .answers-tool {
    background: @gray-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    color: #999;
    padding: 25px 27px;

    > button {
      background-color: transparent;
      outline: none;
      color: #999;

      > .icon {
        width: 30px;
        height: 30px;
        margin-bottom: -6px;
        margin-left: 14px;
      }
    }
  }
}
</style>
