<template>
  <li class="c-question-comment-item" @click="onMore">
    <Avatar :user="user" />
    <div class="comment-info">
      <h3>{{ user.name }}</h3>
      <p>
        <template v-if="comment.reply_user">
          {{ $t('reply.name') }} <span class="username">{{ comment.reply.name }}</span>:
        </template>
        {{ comment.body }}
      </p>
    </div>
    <span class="time">{{ comment.created_at | time2tips }}</span>
  </li>
</template>

<script>
  import * as api from '@/api/question/questions'

  export default {
    name: 'QuestionCommentItem',
    props: {
      comment: { type: Object, required: true },
    },
    computed: {
      user () {
        return this.comment.user
      },
      isMine () {
        return this.user.id === this.$store.state.CURRENTUSER.id
      },
    },
    methods: {
      onMore () {
        const actions = []
        if (this.isMine) {
          actions.push({
            text: this.$t('delete.name'),
            method: this.onDelete,
          })
        } else {
          actions.push({
            text: this.$t('reply.name'),
            method: () => {
              this.$emit('reply', this.user)
            },
          })
        }
        this.$bus.$emit('actionSheet', actions)
      },
      onDelete () {
        api.deleteQuestionComment(this.comment.commentable_id, this.comment.id)
          .then(() => {
            this.$Message.success(this.$t('comment.delete.success'))
            this.$emit('delete', this.comment.id)
          })
      },
    },
  }
</script>

<style lang="less" scoped>
.c-question-comment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid @border-color;
  background-color: #fff;

  .c-avatar {
    flex: none;
    align-self: flex-start;
  }

  .comment-info {
    flex: auto;
    min-width: 0;
    margin-left: 20px;
    font-size: 26px;

    .username {
      color: #333;
    }
    p {
      color: #999;
    }
  }

  .time {
    align-self: flex-start;
    flex: none;
    font-size: 24px;
    color: #bdbdbd;
  }
}
</style>
